<script>
  function scrollToSection2() {
    const section2 = document.getElementById("section2");
    section2?.scrollIntoView({ behavior: "smooth" });
  }
</script>
<nav class="z-10 bg-white mx-4 lg:mx-12 2xl:mx-32 pt-5">
  <div class="mt-3 flex flex-col md:flex-row justify-center gap-4 md:gap-0 md:justify-between w-full items-center content-center ">
    <div class="max-w-[128px]">
      <a on:click={() => scrollToSection2()} href="/" >
        <img src="/logo-original.webp" alt="logo" height="44" width="128px" loading="eager"/>
      </a>
    </div>
    <div class="flex flex-row gap-5 items-center justify-end">
      <a href="#solution" class="font-medium text-md text-slate-900">Our Solution</a>
      <div class="flex items-center justify-center">
          <div class="w-1.5 h-1.5 bg-rose-600 rounded-full"></div>
      </div>
      <a href="#features" class="font-medium text-md text-slate-900">Features</a>
      <div class="flex items-center justify-center">
          <div class="w-1.5 h-1.5 bg-rose-600 rounded-full"></div>
      </div>
      <a href="/" class="font-medium text-md text-slate-900">Contact</a>
    </div>
  </div>
</nav>
