<script lang="ts">
  import { Swiper, SwiperSlide } from "swiper/svelte";
  import SolutionItem from "$lib/common/SolutionItem.svelte";
  import { Pagination } from "swiper";
  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/effect-fade";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";

  const solutions = [
    {
      imgSrc: '/images/icon/image-1.png',
      title: 'Membership',
      href: '#features',
      description: 'Pengelompokan data anggota secara terpusat dan fleksibel.'
    },
    {
      imgSrc: '/images/icon/image-2.png',
      title: 'Content Management',
      href: '#features2',
      description: 'Publikasikan berita, acara, atau gerakan untuk kalangan tertentu.'
    },
    {
      imgSrc: '/images/icon/image-3.png',
      title: 'Broadcast',
      href: '#features2',
      description: 'Kirim informasi ke anggota terpilih dengan kanal secara instan.'
    },
    {
      imgSrc: '/images/icon/image-4.png',
      title: 'Online Payment',
      href: '#features2',
      description: 'Terima pembayaran anggota, acara, atau pengumpulan dana.'
    }
  ];

  const additionalServices = [
    { imgSrc: '/images/icon/image-5.png', title: 'E-commerce' },
    { imgSrc: '/images/icon/image-6.png', title: 'Ticket' },
    { imgSrc: '/images/icon/image-7.png', title: 'Forum/Chat' },
    { imgSrc: '/images/icon/image-8.png', title: 'Gamifikasi' }
  ];
</script>

<div class="relative hero bg-[#FF5C54] bg-opacity-20 px-4 mt-24" id="solution">
  <div class="absolute inset-0 bg-[url('/images/bg-our-service.png')] bg-no-repeat bg-cover opacity-5 z-0"
       style="background-blend-mode: color-dodge;">
  </div>

  <div class="relative z-10 text-[20px] md:text-[24px] lg:text-[30px] mb-10 py-12 font-normal text-center">
    <h4 class="text-primary text-[1rem]">OUR SOLUTION</h4>
    <p class="text-3xl text-slate-950 mt-2 font-semibold">Platform Digital Terintegrasi</p>

    <div class="grid grid-cols-1 md:grid-cols-2 mt-12 gap-4 w-full">
      {#each solutions as solution}
        <SolutionItem {...solution} />
      {/each}
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-4 w-full">
      {#each additionalServices as service}
        <div class="border-white border-[4px] border-dashed rounded-xl flex flex-row gap-1 items-center justify-start px-6 py-6">
          <img src={service.imgSrc} alt={service.title} class="max-w-[48px] max-h-[48px] object-cover rounded-md mr-3"
               height="32px" width="32px" loading="eager" />
          <p class="text-lg text-slate-950">{service.title}</p>
        </div>
      {/each}
    </div>
  </div>
</div>
