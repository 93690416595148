
<div
  style="background-image: url('/bg-hero.webp'); background-size: contain; background-repeat: no-repeat;"
  class="min-h-[0vh] lg:min-h-[60vh] xl:mt-0 2xl:mt-20 mt-0">
  <div class="mx-4 lg:mx-12 2xl:mx-32 flex flex-col md:flex-row items-center lg:gap-12">
    <!--Right Col-->
    <div class="mt-12 ml-[1rem] mr-0 xl:ml-[1rem] md:ml-12 lg:ml-0 md:mt-0 lg:w-1/2 text-center 2xl:pl-16">
      <picture>
        <img
          class="object-contain 2xl:max-w-[580px] xl:max-w-[473px] md:max-w-[370px] lg:max-w-[412px] md:left-0 lg:left-24 xl:left-32 2xl:left-52 md:-top-8 lg:top-44 xl:top-[8rem] 2xl:top-[12.5rem] lg:absolute relative"
          loading="eager"
          width="653"
          height="512"     
          src="/images/hero-image.png"
          alt="img hero"
        />
      </picture>
    </div>

    <!--Left Col-->
    <div
      class="flex flex-col w-full md:w-[43%] lg:w-[65%] justify-center pt-8 pb-[1rem] md:pt-24 md:pb-24">
      <h1 class="text-4xl md:text-4xl xl:text-5xl 2xl:text-6xl text-slate-950 lg:w-[30%]">Community Management Platform</h1>
      <p class="mt-4 text-md md:text-lg xl:text-xl font-normal w-full xl:w-[75%] text-[#93959c]">Kami membantu komunitas, organisasi, suporter, atau kelompok untuk mewujudkan digitalisasi keanggotaan, konten, kegiatan, dan pembayaran.</p>
      <button
        class="btn btn-primary text-white w-full md:w-[80%] lg:w-[40%] xl:w-[30%] mr-[12px] mt-6 mb-5 font-normal"
        data-sveltekit-preload-data
        on:click={()=>window.open('https://wa.me/+6281944835558?text=Halo Tim Munio, saya tertarik untuk mencoba menggunakan Munio, bisakah saya mendapatkan informasi lebih lanjut? Terima kasih!', '_blank')}
        >Miliki Sekarang
      </button>
    </div>
  </div>
</div>

<style lang="css">
    h1 {
      --cursor-width: 10px
    }
</style>